import { Form, Row, Col, Table, Card } from "react-bootstrap";
import InfoJson from "../Doc/info.json";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import React from 'react';

function Elementos() {
  return (
    <>
      <div className="container">
        <h1 style={{ textAlign: "left", fontWeight: "bold" }}>Inicio</h1>
        <Divider color="black" />

        <div className="row my-5">
          <div className="col-md-12">
            <div>
              <h3 className="text-black">Proximos Eventos</h3>
              <Row xs={1} md={3} className="g-4 my-2">
                {InfoJson.map((item, index) => (
                  <Col key={index}>
                    <Card>
                      <Card.Body>
                        <Card.Title
                          className="py-2"
                          style={{ textAlign: "left" }}
                        >
                          {item.title}
                        </Card.Title>
                        <Row className="text-center">
                          <Col md={6}>
                            <Button
                              startIcon={<ArrowCircleRightIcon />}
                              variant="contained"
                              className="form-control btn-block"
                            >
                              <Link
                                className="text-white"
                                style={{
                                  textDecoration: "none",
                                }}
                                to={item.maqueta}
                              >
                                Navegar
                              </Link>
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Divider className="my-3" />

              <Row>
                <Col>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Card.Title>
                          Último registro de Visita Medica
                        </Card.Title>
                        <Col md={12}>
                          <Card className="my-3">
                            <Card.Header className="text-start">
                              <h5>dd/mm/aaaa</h5>
                            </Card.Header>

                            <Card.Body>
                              <div className="justify-content-center my-2">
                                <Form>
                                  <Row>
                                    <Col>
                                      <Stack direction="row" spacing={1}>
                                        <Chip
                                          label="Prescripcion médica"
                                          color="primary"
                                          variant=""
                                        />
                                        <Chip
                                          label="Orden de estudios"
                                          variant=""
                                        />
                                        <Chip
                                          label="Resultado de estudios"
                                          variant=""
                                        />
                                      </Stack>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>

                              <Table className="my-4" striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Fecha visita</th>
                                    <th>Centro de salud</th>
                                    <th>Profesional</th>
                                    <th>Diagnóstico</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>dd/mm/aaaa</td>
                                    <td>Nombre del centro de salud</td>
                                    <td>Nombre del profesional</td>
                                    <td>Descripción del diagnóstico</td>
                                  </tr>
                                </tbody>
                              </Table>

                              <div className="container">
                                <h5 className="text-start my-2">
                                  Indicaciones
                                </h5>
                                <p className="text-start">
                                  Tomar 1 comprimido de Amoxicilina 500mg cada 8
                                  horas por un periodo de 7 días para tratar la
                                  infección en la muela. Comenzar el tratamiento
                                  lo antes posible y continuar hasta completar
                                  los 7 días, incluso si los síntomas de la
                                  infección desaparecen antes. Si experimenta
                                  efectos secundarios como dolor abdominal
                                  intenso, diarrea o erupciones cutáneas,
                                  suspenda el tratamiento y consulte a su médico
                                  de inmediato
                                </p>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="d-flex my-5">
          <Button variant="contained" type="submit">
            <Link
              className="text-white"
              style={{
                textDecoration: "none",
              }}
              to="/"
            >
              Vacio
            </Link>
          </Button>
        </div>
      </div>
    </>
  );
}
export default Elementos;