import * as React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Componentes
import Paper from "@mui/material/Paper";
import { FormControl, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Alert from 'react-bootstrap/Alert';
import Logo from "../components/Logo";
import Loading from '../components/Loading';
//Componentes
import TextFieldInputComponente from '../components/TextFieldInputComponent';
import TextFieldPassWordComponent from '../components/TextFieldPasswordComponent';
import GoogleButton from 'react-google-button'
import Swal from 'sweetalert2';

// Importar componentes propios
import Input from './../components/Input';
import {Formulario, Boton} from './../elementos/Formularios';
import ExpReg from "./../elementos/ExpresionesReg";

//REACT ROUTER
import { Link } from "react-router-dom";

//REACT ROUTER
import AxiosHealth from '../interceptor/axiosHealth';
import axios from 'axios';

import "../css/LoginRegister.css";
import { Margin } from '@mui/icons-material';

//Google
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

const Login = () => {
  localStorage.clear();

  //ExpresionesRegulares
  const expresiones = {
    password: /^.{4,12}$/, // 4 a 12 digitos.
    correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  }

  const [mail, setMail] = useState({campo: '', valido: null});
  const [password, setPassword] = useState({campo: '', valido: null});
  const [isLoading, setIsLoading] = useState(false);
  const [validarReg, setValidarReg] = useState({campo: '', valido: null});

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      validadorlogin();
    }
  }

  //Validador obligatorio
  const validarObligatorio = (estado,cambiarEstado) =>{
    if(estado.valido==null){
      cambiarEstado({...estado, valido : false});
    }else if(estado.valido==''){
      cambiarEstado({...estado, valido : false});
    }
  }

   function validadorlogin() {
    validarObligatorio(mail,setMail);
    validarObligatorio(password,setPassword);
    
    if (mail.valido == true  && password.valido == true){
      setIsLoading(true);
      AxiosHealth.post('login', {
          mail: mail.campo,
          password: password.campo
      }).then(async function (response) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("idUsuario", response.data.usuarioId);
        localStorage.setItem("HMI", response.data.historiaMedicaId)
        await AxiosHealth.get('/diagnosticos/all')
        .then((response) =>{
          localStorage.setItem("DIAG",JSON.stringify(response.data));
        })
        window.location.replace(`../elementos`);
          
        }
        ).catch(error => {
          setIsLoading(false)
          {if(error.request.status == "404"){ingresarCodigo()}}
          console.log(error.request.response)
          console.log(error.request.status)
        });
      }
    }

    function loginGoogle(){
      axios.get('https://backendvtest-a80d56fb412f.herokuapp.com/api/login/google')
    }
//https://accounts.google.com/o/oauth2/auth?access_type=offline&approval_prompt=force&client_id=67224328580-2a1nousaluo8fbkqajj4ukp5c8s31ust.apps.googleusercontent.com&redirect_uri=http://localhost:8080/api/login/google&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/user.gender.read%20https://www.googleapis.com/auth/user.birthday.read
		const handleLoginSuccess = (response) => {
			  //  const token = response.credential;
			// Enviar el token al backend para validación
			axios.get('https://backendvtest-a80d56fb412f.herokuapp.com/api/login/google', {
				// token: token
			}).then(response => {
				console.log("Login successful:", response.data);
			}).catch(error => {
				console.error("Error during login:", error);
			});
		};
	
		const handleLoginFailure = (error) => {
			console.error("Login failed:", error);
		};

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-start',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  useEffect(() => {
    if (validarReg.campo !== '') {
      AxiosHealth
        .put(`/usuarios/verificarCuenta`, {
          mail: mail.campo,
          codigo: validarReg.campo,
        })
        .then(function (response) {
          if(response.request.status==201){
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Su cuenta fue activada correctamente. Será redirigido para que se loguee.',
              showConfirmButton: false,
              timer: 3000
            })
            .then(() => {
              AxiosHealth
              .post(`/historiasMedicas`, {
                idPaciente: response.data.id
              })
              .then(function (response) {
                navigate("/");
              })
            })
          }
        })
				.catch((error) => {
          if(error.request.status==500){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Tiene que ingresar un codigo para validar',
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>'
            })
            .then(() => {
              ingresarCodigo()
             })
          }else if(error.request.status==406){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.request.response,
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>'
            })
            .then(() => {
              navigate("/");
             })
          }else if(error.request.status==417){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'El codigo ingresado es incorrecto, por favor verifíquedo y vuelva a intentar.',
              footer: '<a href="">Necesita ayuda hacer click aqui!</a>'
            })
            .then(() => {
              ingresarCodigo()
             })
          }
       });
    }
  }, [validarReg]);

  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate('/registroConMail');
  };

  const ingresarCodigo = () => {
    Swal.fire({
      title: 'Ingrese el código de activacion que recibió por mail',
      input: 'text',
      inputPlaceholder: 'Ingrese el código de activacion aquí',
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
    }).then(({ value }) => {
      setValidarReg({...validarReg, campo: value})
    });
  };

  return (
    <div id="FondoLogin">
      <>
        {isLoading && <Loading message={"Iniciando sesión..."} />}
        <div id="Posicionar-Carta">
          <Paper elevation={1} id="Carta">
            <div id="Posicionar-Container">
              <Logo />
              <form className="my-5" method="put" onKeyDown={handleKeyDown}>
                <div id="Columna-Inputs">
                  <FormControl method="post" id="Input">
                    <TextFieldInputComponente
                      type="text"
                      required
                      leyendaHelper="mail@dominio"
                      leyendaError="El correo solo puede contener letras, numeros, puntos, guiones y guion bajo."
                      id="mail_inicio_sesion"
                      label="Mail"
                      estado={mail}
                      cambiarEstado={setMail}
                      expresionRegular={ExpReg.correo}
                    />
                  </FormControl>
                  <FormControl method="post" id="Input">
                    <TextFieldPassWordComponent
                      leyendaHelper="Ingrese su contraseña."
                      leyendaError="La contraseña tiene que ser de 4 a 12 dígitos."
                      label="Contraseña_inicio_sesion"
                      estado={password}
                      cambiarEstado={setPassword}
                      expresionRegular={ExpReg.password}
                    />
                  </FormControl>
                </div>
              </form>

              <Button
                sx={{ width: "70%" }}
                startIcon={<ArrowCircleRightIcon />}
                className="form-control btn-block"
                variant="outlined"
                id="Boton"
                type="submit"
                onClick={() => validadorlogin()}
              >
                Iniciar sesion
              </Button>
              <Button
                sx={{ width: "70%" }}
                startIcon={<ArrowCircleRightIcon />}
                variant="contained"
                className="form-control btn-block"
                onClick={() => navigateToRegister()}
              >
                Registrar
              </Button>

              <Divider className="my-3" />

              <Link style={{ marginLeft: "40%" }} to="/recupero">Olvide mi contraseña</Link>

              <Divider className="my-3" />

              {/* <Button sx={{ width: "73%" }}>
                <GoogleButton
                  style={{ width: "100%" }}
                  label="Ingresar con Google"
                  onClick={() => {
                    loginGoogle();
                  }}
                />
              </Button> */}

							<div>
							<GoogleOAuthProvider clientId="67224328580-2a1nousaluo8fbkqajj4ukp5c8s31ust.apps.googleusercontent.com">
      <div>
            <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>
    </GoogleOAuthProvider>

		<Button sx={{ width: "73%" }}>
                <GoogleButton
                  style={{ width: "100%" }}
                  label="Ingresar con Google"
                  onClick={() => {
                    loginGoogle();
                  }}
                />
              </Button>
               </div>

            </div>
          </Paper>
        </div>
      </>
    </div>
  );
};

export default Login;
