import React from 'react';
const Error404 = () => {
    return (
      <>
        <h2>ERROR</h2>
        <h3>404 Cookies</h3>
      </>
    );
  }

  export default Error404